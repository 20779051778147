@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    body, html {
        font-family: 'Roboto', 'Oswald', 'Open Sans', sans-serif;
    }

    button {
        @apply border px-3 py-2 rounded-full
    }
}



